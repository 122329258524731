<template>
  <div class="home tui-window tui-border-double w-full my-5">
    <h1>About me</h1>
    <p class="m-3">Marek was born in the 80s in Poland. Started to learn HTML when was 14 years old. In 2006 created his
      first CMS, soon after refactored and renamed it to RadioCMS - at the time it was the one and only CMS created for
      internet radio stations.
      The Same time joined DesignLand.pl team, where he has had the opportunity to work as PHP Developer on a number of
      great projects. In 2007 created PingPongTV - a YouTube clone, without html5 yet, so want so easy and required a
      very specific
      server also configured by him. Details in
      <router-link to="projects">projects</router-link>
      .
      In 2008, he moved to the UK, where currently lives.
    </p>
    <p class="m-3">
      At the beginning of 2020 made decision about going back to IT. In 2022 joined the team at <a
        href="https://www.artworking.co.uk/" target="_blank">ArtWorking</a> as a Full Stack Developer.
    </p>

  </div>
</template>
 