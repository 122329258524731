<template>

  <svg enable-background="new 0 0 53.763 53.763" height="53.763px" id="Layer_1" version="1.1"
       viewBox="0 0 53.763 53.763" width="53.763px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="26.881" cy="26.881" r="26.881"/>
    <g><path d="M36.198,16.519c0.771,1.071,1.155,2.353,1.155,3.845c0,1.538-0.389,2.774-1.166,3.708   c-0.435,0.524-1.075,1.003-1.922,1.436c1.286,0.467,2.256,1.208,2.91,2.221c0.654,1.015,0.981,2.245,0.981,3.691   c0,1.493-0.374,2.832-1.121,4.017c-0.477,0.786-1.071,1.447-1.784,1.982c-0.805,0.615-1.753,1.037-2.847,1.265   c-1.093,0.229-2.279,0.342-3.56,0.342h-11.35v-25.19h12.173C32.741,13.882,34.917,14.776,36.198,16.519z M22.52,18.211v5.554h6.123   c1.094,0,1.981-0.208,2.664-0.624c0.682-0.416,1.023-1.154,1.023-2.213c0-1.173-0.451-1.948-1.354-2.324   c-0.778-0.262-1.771-0.393-2.977-0.393H22.52z M22.52,27.935v6.717h6.116c1.092,0,1.942-0.148,2.551-0.444   c1.104-0.547,1.655-1.595,1.655-3.145c0-1.31-0.534-2.21-1.604-2.7c-0.597-0.273-1.436-0.416-2.518-0.428H22.52z" fill="#FFFFFF"/></g></svg>


</template>

<script>
export default {
  name: 'bootstrapLogo'
};
</script>
