<template>
  <div class="home tui-window tui-border-double w-full my-5">
    <h1>My skills</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2">
      <div class="child">
        <h2>Languages:</h2>
        <ul>
          <li> PHP</li>
          <li> HTML</li>
          <li> CSS</li>
          <li> JS</li>
        </ul>
        <h2>Frameworks & Libs:</h2>
        <ul>
          <li> LARAVEL</li>
          <li> Vue.js</li>
          <li> Bootstrap</li>
          <li> Tailwind</li>
        </ul>
        <h2>Other:</h2>
        <ul>
          <li> Composer</li>
          <li> Npm</li>
          <li> Webpack</li>
          <li> Git</li>
        </ul>
        <h2>Extra:</h2>
        <ul>
          <li> First Aider</li>
          <li> Fire warden</li>
          <li> DJ</li>
        </ul>
      </div>
      <div class="child invisible sm:visible">
        <div class="inner">
          <phpLogo/>
          <bootstrapLogo/>
          <cssLogo/>
          <djLogo/>
          <fiLogo/>
          <gitLogo/>
          <htmlLogo/>
          <jsLogo/>
          <laravelLogo/>
          <npmLogo/>
          <vueLogo/>
        </div>

      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
svg {
  height: 5rem;
  width: 5rem;
  margin-bottom: .5rem;
}


.child {
  flex: 1;
  padding-bottom: 1em;

  &:nth-child(2) {
    position: relative;
    overflow: hidden;
    padding-bottom: 2em;
    height: 95%;
  }

}
h2{
  margin-top: .5rem;
  margin-left: 1.2rem;
  text-align: left;
}

.inner {
  position: absolute;
  left: 40%;
  width: 100%;
  height: 80%;
  animation: moveup 15s infinite;
  animation-timing-function: linear;
}

@keyframes moveup {


  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(-220%);
  }

}

li {
  &::before{
    content: '✓';
    color: #A80000;
    background: #00A8A8;

  }
  margin-left: 2rem;
}
</style>
<script>
import bootstrapLogo from "@/components/techsLogos/bootstrapLogo";
import cssLogo from "@/components/techsLogos/cssLogo";
import djLogo from "@/components/techsLogos/djLogo";
import fiLogo from "@/components/techsLogos/fiLogo";
import gitLogo from "@/components/techsLogos/gitLogo";
import htmlLogo from "@/components/techsLogos/htmlLogo";
import jsLogo from "@/components/techsLogos/jsLogo";
import laravelLogo from "@/components/techsLogos/laravelLogo";
import npmLogo from "@/components/techsLogos/npmLogo";
import phpLogo from "@/components/techsLogos/phpLogo";
import vueLogo from "@/components/techsLogos/vueLogo";

export default {
  components: {
    phpLogo,
    bootstrapLogo,
    cssLogo,
    djLogo,
    fiLogo,
    gitLogo,
    htmlLogo,
    jsLogo,
    laravelLogo,
    npmLogo,
    vueLogo
  }

}
</script>