<template>
  <svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px"
       xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x35_88_x2C__first_aid_x2C__medical_x2C__tablets"><g><g><g><g><path d="M426.348,445.956h-340c-38.598,0-70-31.401-70-70v-200c0-38.598,31.402-70,70-70h340c38.599,0,70,31.402,70,70v200       C496.348,414.555,464.946,445.956,426.348,445.956z M86.347,125.957c-27.57,0-50,22.43-50,50v200c0,27.57,22.43,50,50,50h340       c27.57,0,50-22.43,50-50v-200c0-27.57-22.43-50-50-50H86.347z"/></g><g><path d="M156.347,485.956h-60c-11.028,0-20-8.972-20-20v-30c0-5.522,4.477-10,10-10h80c5.523,0,10,4.478,10,10v30       C176.347,476.984,167.375,485.956,156.347,485.956z M96.347,445.956v20h60c0.009,0,0.013-0.001,0.013-0.001l-0.008-19.999       H96.347z"/></g><g><path d="M426.348,485.956h-60c-11.028,0-20-8.972-20-20v-30c0-5.522,4.478-10,10-10h80c5.522,0,10,4.478,10,10v30       C446.348,476.984,437.376,485.956,426.348,485.956z M366.348,445.956v20h60c0.009,0,0.013-0.001,0.013-0.001l-0.009-19.999       H366.348z"/></g><g><path d="M376.348,125.957h-240c-5.523,0-10-4.477-10-10v-20c0-38.598,31.402-70,70-70h120c38.598,0,70,31.402,70,70v20       C386.348,121.479,381.87,125.957,376.348,125.957z M146.347,105.957h220v-10c0-27.57-22.43-50-50-50h-120       c-27.57,0-50,22.43-50,50V105.957z"/></g><g><g><path d="M296.348,405.956h-80c-5.523,0-10-4.478-10-10v-70h-70c-5.523,0-10-4.478-10-10v-80c0-5.523,4.477-10,10-10h70v-70        c0-5.523,4.477-10,10-10h80c5.522,0,10,4.477,10,10v70h70c5.522,0,10,4.477,10,10v80c0,5.522-4.478,10-10,10h-70v70        C306.348,401.479,301.87,405.956,296.348,405.956z M226.347,385.956h60v-70c0-5.522,4.478-10,10-10h70v-60h-70        c-5.522,0-10-4.477-10-10v-70h-60v70c0,5.523-4.477,10-10,10h-70v60h70c5.523,0,10,4.478,10,10V385.956z"/></g>
    <g><path d="M256.347,365.956c-5.523,0-10-4.478-10-10v-160c0-5.523,4.477-10,10-10s10,4.477,10,10v160        C266.348,361.479,261.87,365.956,256.347,365.956z"/></g>
    <g><path d="M336.348,285.956h-160c-5.523,0-10-4.478-10-10s4.477-10,10-10h160c5.522,0,10,4.478,10,10        S341.87,285.956,336.348,285.956z"/></g></g></g></g></g></g>
    <g id="Layer_1"/></svg>
</template>

<script>
    export default {
        name: 'fiLogo'
    };
</script>
