<template>
  <h2>Sms me</h2>
  <h3>Description</h3>
  <p>
    System let register everyone, after confirming user mobile number by text allow texting other users, without knowing
    their numbers.
  </p>
  <p>Used</p>
  <ul class="done">
    <li>Laravel</li>
    <li>Livewire</li>
    <li>Tailwind</li>
    <li>Redis</li>
    <li>sms gateway by Twilio</li>
  </ul>

  <h3>Links</h3>
  <p class="p-4">
    <a href="https://te.aw3.pl/" target="_blank" class="m-16">Sms gateway</a>
  </p>

</template>

<script>
export default {
  name: 'smsmme'
};
</script>
