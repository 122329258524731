<template>
  <h2>ProRadio</h2>
  <h3>Description</h3>
  <p>
    One page website.
    Simple as it only can be with
  </p>

  <ul class="done">
    <li>HTML</li>
    <li>CSS (Sass)</li>
    <li>Animate.css</li>
    <li>Slick.js</li>

  </ul>
  <h3>Links</h3>
  <p class="p-4">
    <a href="https://proradio.aw3.pl/" target="_blank" class="m-16">ProRadio</a>
  </p>  <p class="p-4">
    <a href="https://github.com/iksorek/proradio" target="_blank" class="m-16">Code on github</a>
  </p>
</template>

<script>
export default {
  name: 'proradio'
};
</script>
