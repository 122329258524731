<template>
  <h2>PingPongTV - the Youtube clone</h2>
  <h3>Description</h3>
  <p>Created in 2007, so without html5 or superfast fiber internet. Upload handling in Java (only way to get progress
    bar at the time). </p>
  <p>
    Comments / rating in AJAX (jQuery). Very specific server configured by me as need huge maximum
    upload file, system needs to convert video to flv, so used mencoder, flv2-tools and mplayer to generate thumbnails.
    Also, it was my first project where I have used template system, here: SMARTY. </p>
  <p>
    As flash player is no longer
    supported, videos are non-viewable any more (sadly no maintenance from 2008).
  </p>


  <h3>Links</h3>
  <p class="p-4">
    <a href="http://pingpongtv.pl/" target="_blank" class="m-16">PingpongTV</a>
  </p>
</template>

<script>
export default {
  name: 'pingpongtv'
};
</script>
