<template>
  <svg id="Icons" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><circle cx="16" cy="16" r="1"/><path
      d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M6,17c-0.6,0-1-0.4-1-1C5,9.9,9.9,5,16,5c0.6,0,1,0.4,1,1   s-0.4,1-1,1c-5,0-9,4-9,9C7,16.6,6.6,17,6,17z M9,17c-0.6,0-1-0.4-1-1c0-4.4,3.6-8,8-8c0.6,0,1,0.4,1,1s-0.4,1-1,1   c-3.3,0-6,2.7-6,6C10,16.6,9.6,17,9,17z M16,21c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S18.8,21,16,21z"/></g></svg>

</template>

<script>
    export default {
        name: 'djLogo'
    };
</script>
