<template>
  <svg height="256px" preserveAspectRatio="xMinYMin meet" version="1.1" viewBox="0 0 256 256" width="256px"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="#F35045">
      <path
          d="M160.444667,208 C162.444667,210.888667 163.666667,210.666667 165.222,210 C166.777333,209.333333 246.778,182.333333 249.222,181.444667 C251.666,180.556 250.888667,179.778 250.111333,178.555333 C249.334,177.332667 222.666667,141.111333 221.333333,139 C220,136.888667 219.333333,137.333333 217.222,138 L132.416667,160.066667 C132.416667,160.066667 158.444667,205.111333 160.444667,208 L160.444667,208 Z"/>
      <path
          d="M256,171.115333 L256,128.716667 C246.458667,131.259333 234.391333,134.472667 229.627333,135.74 C236.814,145.356 248.05,160.404667 256,171.115333 L256,171.115333 Z"/>
      <path
          d="M229.545333,83.5213333 C228.238,83.818 199.298,89.048 197.515333,89.2853333 C195.732667,89.5226667 196.326667,90.5333333 197.04,91.5433333 L222.507333,126.486 C222.507333,126.486 253.646,118.760667 255.548,118.344667 C255.702,118.310667 255.852667,118.274667 256,118.238 L256,113.611333 C250.34,106.434 234.919333,86.8933333 233.586667,85.0673333 C231.982667,82.868 230.852667,83.2246667 229.545333,83.5213333 L229.545333,83.5213333 Z"/>
      <path
          d="M47.456,28.634 C53.7226667,28.3493333 54.2946667,29.7753333 57.048,33.858 L126.218667,149.5 L213.192,128.664667 C208.373333,121.834 186.496667,90.8186667 184.382667,87.8593333 C182.006,84.5313333 184.442,82.986 188.304667,82.3326667 C192.167333,81.6793333 225.444667,76.0933333 228.297333,75.6773333 C231.15,75.2613333 233.408667,74.2513333 238.043333,79.8373333 C240.414667,82.6953333 248.455333,92.7073333 256,102.092 L256,48 C256,34.778 251.305333,23.472 241.916667,14.0833333 C232.528,4.69466667 221.222,0 208,0 L48,0 C34.778,0 23.472,4.69466667 14.0833333,14.0833333 C8.698,19.4686667 4.86533333,25.4873333 2.56866667,32.134 C18.2466667,30.846 43.07,28.8333333 47.456,28.634 L47.456,28.634 Z"/>
      <path
          d="M168.288667,223.564 C160.954667,225.786 157.666,226.888667 152.888667,220.222 C149.310667,215.228667 129.962,180.378667 120.474667,163.198667 C102.52,167.901333 69.6606667,176.477333 60.1853333,178.754 C50.9366667,180.976 46.9873333,175.431333 45.468,172.013333 C44.4026667,169.616667 17.2893333,111.159333 0,73.6506667 L0,208 C0,221.222667 4.69466667,232.527333 14.0833333,241.916667 C23.472,251.305333 34.778,256 48,256 L208,256 C221.222,256 232.528,251.305333 241.916667,241.916667 C251.305333,232.527333 256,221.222667 256,208 L256,192.218667 C236.359333,199.402667 173.23,222.066667 168.288667,223.564 L168.288667,223.564 Z"/>
      <path
          d="M60.412,165.288 C63.0113333,164.693333 111.724667,153.034667 112.764667,152.812 C113.804667,152.589333 114.472667,151.994667 113.358667,150.064667 C112.244667,148.134667 48.4253333,37.9753333 48.4253333,37.9753333 C47.836,36.964 48.004,36.6273333 46.4033333,36.7113333 C44.976,36.7866667 8.86533333,40.0086667 0.504666667,40.7073333 C0.258,42.446 0.109333333,44.2246667 0.044,46.038 C8.53133333,63.4126667 57.2426667,163.874667 57.516,164.693333 C57.8133333,165.584667 57.8126667,165.882 60.412,165.288 L60.412,165.288 Z"/>
    </g>
  </svg>
</template>

<script>
    export default {
        name: 'laravelLogo'
    };
</script>
