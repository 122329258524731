<template>
  <h2>Car dealer</h2>
  <h3>Description</h3>
  <p>
    Car sale system based on Laravel.
    Main features:
  </p>

  <ul class="done">
    <li>Front-end use caches system on Redis.</li>
    <li>Simple but powerful admin panel lets user manage cars in database.</li>
    <li>Mark as sold or reserved</li>
    <li>Page settings</li>
    <li>Messages system</li>
    <li>My Account</li>
  </ul>
  <p>
    Sadly never gone live.
  </p>
  <h3>Links</h3>
  <p class="p-4">
    <a href="https://cardealer.aw3.pl/" target="_blank" class="m-16">Car Dealer Demo</a>
  </p>

</template>

<script>
export default {
  name: 'cardealer'
};
</script>
