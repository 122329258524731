<template>
  <svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px"
       xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x33_67-vuejs"><g><polygon points="392.46,59.011 313.5,59.011 256.001,149.983 206.716,59.011 26.001,59.011     256.001,452.989 485.999,59.011   " style="fill:#41B883;"/><polygon
      points="83.192,91.867 138.433,91.867 256.001,295.378 373.464,91.867 428.705,91.867     256.001,387.787 83.192,91.867   "
      style="fill:#35495E;"/></g></g>
    <g id="Layer_1"/></svg>
</template>

<script>
    export default {
        name: 'vueLogo'
    };
</script>
