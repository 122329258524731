<template>
  <h2>Transport management system - current pet project</h2>
  <h3>Description</h3>
  <p>
    SaaS for transport companies like Man&Van, removals etc. Based on Laravel + Livewire
  </p>
  <h3>Works</h3>
  <ul class="done">
    <li>Login and permissions based laravel with same changes</li>
    <li>Manage Vehicles - gets details from DVLA database, plus tax, insurance, service etc</li>
    <li>Manage Runs with associating customer, vehicle, update prices, times, etas, etc</li>
    <li>Manage customers in separate module, or add new when adding new run</li>
    <li>Give quotes, based on price per mile on distance + hourly rate. All calculated by system, based on just
      two
      postcodes.
    </li>
    <li>Settings - where user can set price per mile and / or per hour</li>
    <li>API to allow users to check price and availability of vehicles via existing company website</li>

  </ul>
  <h3>Planned next steps:</h3>
  <ul class="undone">
    <li>Payment plans</li>
    <li>Super-admin controls</li>
  </ul>

  <h3>Links</h3>
  <p class="pt-4">
    <a href="https://www.xtms.uk" target="_blank" class="ml-16">XTMS Demo</a> - user panel
    <br>
    Login: test@xtms.uk<br>
    Pass: password
    <br>(DB is being reseeded everyday)
  </p>
  <p class="pb-4">
    <a href="https://manandvan.xtms.uk" target="_blank" class="ml-16">Man and van</a> - Sample website use XTMS API
  </p>

  <p class="pb-4">
    <a href="https://github.com/iksorek/xtms" target="_blank" class="ml-16">Code on Github</a>
  </p>

</template>

<script>
export default {
  name: 'Xtms'
};
</script>
