<template>
  <h2>Balance Accountancy LTD</h2>
  <h3>Description</h3>
  <p>
    Company website. Simple frontend with tailwind. Even too advanced backend - Laravel.
  </p>

  <ul class="done">
    <li>Mobile first.</li>
    <li>Two languages</li>
    <li>Admin panel let manage content, email settings and contact form settings</li>
  </ul>
  <h3>Links</h3>
  <p class="p-4">
    <a href="https://balanceaccountancyltd.co.uk/" target="_blank" class="m-16">Balance Accountancy Ltd</a>
  </p>

</template>

<script>
export default {
  name: 'balance'
};
</script>
