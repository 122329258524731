<template>
  <div class="tui-window tui-border-double w-full my-5">
    <h1 @click="loadedProject = null" class="cursor-pointer">My projects</h1>

    <div class="description" v-if="loadedProject && loadedProject != 'pet'">

      <component :is="loadedProject"/>
    </div>
    <div class="other md:w-3/4 p-2 mx-auto m-5" v-if="!loadedProject">
      <div class="project">
        <p @click="loadedProject = 'xtms'">Pet project - Transport management system</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'balance'">Balance Accountancy LTD</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'proradio'">Pro Radio</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'cardealer'">Car Dealer</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'polsky'">Polsky Scaffolding</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'smsme'">SMS me</p>
      </div>
      <div class="project">
        <p @click="loadedProject = 'pingpongtv'">PingpongTV</p>
      </div>


    </div>
    <div class="info m-4" v-if="!loadedProject">

      <p class="text-center">Click on project name for details</p>
    </div>
    <div class="info m-4" @click='loadedProject = null' v-if="loadedProject">
      <p class="text-center cursor-pointer">Go back</p>
    </div>


  </div>
</template>

<style lang="scss">
@keyframes blink {
  FROM {
    opacity: 1;
  }
  TO {
    opacity: 0;
  }

}

.project {
  border-top: 2px solid #00a800;

  &:nth-last-child(1) {
    border-bottom: 2px solid #00a800;

  }

  &:nth-child(odd) {
    border-top: 2px solid #00a800;
    border-left: 2px solid #00a800;

  }

  &:nth-child(even) {
    border-right: solid #00a800;
    text-align: end;

  }

  p {
    padding: 1em;

    &:hover {
      animation: .15s infinite alternate blink;
    }
  }


  @apply cursor-pointer;

}


.animate {
  @apply cursor-pointer;

}

.description {
  margin: 1.5rem;
}

h2 {
  text-align: center;
}

h3 {
  margin: .5rem 2rem;
  font-size: 1.3rem;
}

.done > li {
  &::before {
    content: '✔';
  }
}

.undone > li {
  &::before {
    content: '❌';
  }
}
</style>

<script>
import xtms from "@/components/Projects/xtms.vue";
import cardealer from "@/components/Projects/cardealer.vue";
import proradio from "@/components/Projects/proradio.vue";
import polsky from "@/components/Projects/polsky.vue";
import smsme from "@/components/Projects/smsme.vue";
import pingpongtv from "@/components/Projects/pingpongtv.vue";
import balance from "@/components/Projects/balance";

export default {
  data() {
    return {
      loading: false,
      projects: null,
      loadedProject: null,
    }
  },
  components: {xtms, cardealer, proradio, polsky, smsme, pingpongtv, balance}

}

</script>