<template>
  <h2>Polsky Scaffolding</h2>
  <h3>Description</h3>
  <p>
    Scaffolding company website. Simple Html + Css project.
  </p>
  <p>Bit of PHP to generate gallery from images in folder, so customer can upload new photos by himself, without any IT knowledge.</p>

  <h3>Links</h3>
  <p class="p-4">
    <a href="https://www.polskyscaffolding.co.uk/" target="_blank" class="m-16">Polsky Scaffolding</a>
  </p>
</template>

<script>
    export default {
        name: 'polsky'
    };
</script>
