<template>
  <svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px"
       xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="_x32_33-node-js"><g><path d="M255.908,485.999c-6.116,0-12.323-1.642-17.708-4.746l-56.319-33.316    c-8.397-4.747-4.29-6.389-1.551-7.303c11.226-3.925,13.508-4.745,25.465-11.592c1.279-0.729,2.922-0.457,4.199,0.366    l43.266,25.648c1.552,0.912,3.741,0.912,5.202,0l168.592-97.304c1.553-0.913,2.555-2.738,2.555-4.563V158.583    c0-1.917-1.002-3.651-2.645-4.654L258.463,56.716c-1.551-0.913-3.65-0.913-5.202,0L84.852,154.019    c-1.644,0.913-2.647,2.738-2.647,4.655v194.515c0,1.825,1.003,3.65,2.647,4.473l46.188,26.653    c25.102,12.504,40.436-2.191,40.436-17.068V175.195c0-2.738,2.19-4.837,4.93-4.837h21.359c2.647,0,4.928,2.099,4.928,4.837    v192.143c0,33.407-18.256,52.576-49.928,52.576c-9.768,0-17.435,0-38.794-10.588l-44.179-25.467    c-10.954-6.299-17.708-18.074-17.708-30.76V158.583c0-12.596,6.754-24.462,17.708-30.76L238.2,30.519    c10.679-6.024,24.828-6.024,35.416,0l168.594,97.394c10.953,6.298,17.707,18.073,17.707,30.761v194.515    c0,12.596-6.754,24.371-17.707,30.761l-168.594,97.303C268.23,484.357,262.113,485.999,255.908,485.999z M392.006,294.224    c0-36.421-24.646-46.096-76.4-52.941c-52.396-6.938-57.689-10.498-57.689-22.729c0-10.131,4.473-23.641,43.266-23.641    c34.596,0,47.375,7.485,52.668,30.853c0.457,2.19,2.467,3.833,4.746,3.833h21.91c1.367,0,2.645-0.547,3.559-1.552    c0.914-1.003,1.369-2.373,1.275-3.743c-3.375-40.252-30.121-58.965-84.158-58.965c-48.103,0-76.764,20.264-76.764,54.311    c0,36.876,28.569,47.101,74.664,51.662c55.225,5.387,59.514,13.511,59.514,24.373c0,18.805-15.15,26.835-50.658,26.835    c-44.635,0-54.403-11.227-57.689-33.406c-0.365-2.373-2.374-4.107-4.837-4.107h-21.817c-2.738,0-4.836,2.191-4.836,4.836    c0,28.39,15.426,62.254,89.271,62.254C361.336,352.005,392.006,330.916,392.006,294.224L392.006,294.224z" style="fill:#539E43;"/></g></g>
    <g id="Layer_1"/></svg>
</template>

<script>
    export default {
        name: 'jsLogo'
    };
</script>
